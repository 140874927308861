<template>
  <a-modal
    v-model="visible"
    class="modal-user create-user"
    title="Usuario"
    :maskClosable="false"
  >
    <a-form :form="form" @submit.prevent="submitAddData">
      <a-row type="flex" justify="space-between">
        <a-col :span="11" class="user-data">
          <div class="ant-form-item-label">
            <span class="label-telephone">Teléfono</span>
          </div>
          <div class="user-phone">
            {{ userPhone }}
          </div>
        </a-col>
        <a-col :span="11">
          <a-form-item label="Tipo" class="user-data">
            <a-select
              style="width: 100%;"
              v-decorator="[
                'type_phone',
                { rules: [{ required: true }], initialValue: '0' },
              ]"
            >
              <a-select-option value="0">
                Móvil
              </a-select-option>
              <a-select-option value="1">
                Casa
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-between" class="user-data">
        <a-col :span="11">
          <a-form-item label="Nombre">
            <a-input
              v-decorator="[
                'first_name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'El campo nombre es requerido.',
                    },
                  ],
                  initialValue: '',
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item label="Apellidos">
            <a-input
              v-decorator="[
                'last_name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'El campo apellido es requerido.',
                    },
                  ],
                  initialValue: '',
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button key="back" @click="goBack">
        Atrás
      </a-button>
      <a-button key="create" type="primary" @click="submitAddData">
        Crear
      </a-button>
    </template>
  </a-modal>
</template>

<script>

export default {
  name: "CreateUser",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userPhone: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, {
        name: "coordinated",
      }),
    };
  },
  methods: {
    goBack() {
      this.$emit("cancel");
    },
    async createUser() {
      let data = this.form.getFieldsValue();
      data = { ...data, phone: this.userPhone.replace(/\s/g, "") };
      this.$emit("createAddress", data)
    },
    submitAddData() {
      this.form.validateFields((err) => {
        if (!err) {
          this.createUser();
        }
      });
    },
  },
};
</script>
