<template>
  <div>
    <div class="xpress">
      <User
        :visible="isUserVisible"
        v-on:createUser="createUser"
        v-on:loadAddress="loadAddress"
        v-on:selectAddress="selectAddress"
        v-on:createAddress="createAddress"
        @cancel="visible = false"
      />
      <CreateUser
        :visible="isCreateUserVisible"
        :userPhone="result.nationalNumber"
        v-on:createAddress="createAddress"
        @cancel="hideCreateUser"
      />
    </div>
  </div>
</template>
<script>
import User from "@/components/Xpress/User";
import CreateUser from "@/components/Xpress/CreateUser";
export default {
  name: "Xpress",
  components: {
    User,
    CreateUser,
  },
  data() {
    return {
      visible: false,
      createUserVisible: false,
      result: Object,
      user: null,
      feeOrder: 0,
    };
  },
  mounted() {
    this.visible = true;
  },
  computed: {
    isUserXpressLogged() {
      return this.$store.getters.isUserXpressLogged;
    },
    isXpress() {
      return this.$store.getters.isXpress;
    },
    establishment() {
      return this.$store.getters.getEstablishment;
    },
    isUserVisible() {
      return !this.$store.getters.isNewOrder && this.visible;
    },
    isCreateUserVisible() {
      return !this.$store.getters.isNewOrder && this.createUserVisible;
    },
    xpressAddress() {
      return this.$store.getters.getXpressAddress;
    },
  },
  methods: {
    createUser(value) {
      this.result = value;
      this.createUserVisible = true;
      this.visible = false;
    },
    createAddress(value) {
      this.user = value;
      this.$router.push({
        name: "XpressAddress",
        params: { user: value, eid: this.establishment },
      });
    },
    loadAddress() {
      this.$router.push({
        name: "XpressAddress",
        params: { user: this.xpressAddress.user, eid: this.establishment },
      });
    },
    selectAddress(fee, value = "XPRESS") {
      this.feeOrder = fee;
      this.$router.push({
        name: "XpressMenu",
        params: { orderType: value, fee: this.feeOrder },
      });
    },
    hideCreateUser() {
      this.createUserVisible = false;
      this.visible = true;
    },
  },
};
</script>
